<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>Products</h3>
          </v-col>
          <v-col cols="2" align="right">
            <AddProduct style="display: inline-block" @reloadTask="initialData" />
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="items" :items-per-page="15" item-key="idStudent" class="table-content" :no-data-text="dataMessage">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddProduct from "../components/AddProduct.vue";
export default {
  components: {
    AddProduct,
  },
  data: () => ({
    overlay: false,
    items: [],
    headers: [
      { text: "index", value: "index" },
      { text: "photo", value: "studentName" },
      { text: "title", value: "sectionName" },
      { text: "description", value: "level" },
    ],
  }),
  methods: {
    initialData() {},
  },
};
</script>

<style lang="scss" scoped></style>
