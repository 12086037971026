import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // apiUrl: "http://localhost:4900/api",
    apiUrl: "https://map.smartuni.app/api",
    user: null,
    userData: null,
    options: {
      venue: "mappedin-demo-mall",
      clientId: "5eab30aa91b055001a68e996",
      clientSecret: "RJyRXKcryCMy4erZqqCbuB1NbR66QTGNXVE0x3Pg6oCIlUR1",
    },
    workTypes: [
      { name: "Company", id: 1 },
      { name: "Sellers", id: 2 },
      { name: "Decor", id: 3 },
      { name: "Cargo & Logistics", id: 4 },
      { name: "Advertisers", id: 5 },
    ],
    loginUser: null,
  },
  getters: {},
  mutations: {
    GET_USER_DATA(state, userData) {
      state.user = userData;
    },
    GET_ADMIN_DATA(state, loginUser) {
      console.log(loginUser);
      state.loginUser = loginUser;
    },
  },
  actions: {
    getUserDate({ commit }) {
      let data = localStorage.getItem("company");

      if (data) {
        var base64Url = data.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        let text = JSON.parse(jsonPayload);
        console.log(text);
        this.state.userData = text;
      }
      commit("GET_USER_DATA", JSON.parse(data));
    },
    getAdminDate({ commit }) {
      let data = localStorage.getItem("userLogin");

      if (data) {
        var base64Url = data.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        let text = JSON.parse(jsonPayload);
        console.log(text);
        this.state.loginUser = text;
      }
      // commit("GET_ADMIN_DATA", JSON.parse(data));
    },
  },
  modules: {},
});
