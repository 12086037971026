<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="490">
      <v-card>
        <v-card-title class="text-h7"> Are you sure to change the company status? </v-card-title>
        <v-card-text>please choose the status below.</v-card-text>
        <v-card-text>
          <v-radio-group v-model="statusId" column>
            <v-radio color="success" value="2">
              <template v-slot:label>
                <div><strong class="success--text">Accept</strong></div>
              </template>
            </v-radio>
            <v-radio color="warning" value="1">
              <template v-slot:label>
                <div><strong class="warning--text">Pending</strong></div>
              </template>
            </v-radio>
            <v-radio color="error" value="3">
              <template v-slot:label>
                <div><strong class="error--text">Reject</strong></div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="show = false"> Cancel </v-btn>
          <v-btn color="green darken-1" text @click="saveNewStatus"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-icon dark @click="show = true" color="primary"> mdi-cached </v-icon>
  </v-row>
</template>

<script>
export default {
  props: {
    companyId: Number,
  },
  data: () => ({
    show: false,
    statusId: null,
  }),
  methods: {
    saveNewStatus() {
      console.log(this.statusId);
      let self = this;

      self.$http
        .put(`${self.$store.state.apiUrl}/companyStatus/${self.companyId}`, { statusId: self.statusId })
        .then((res) => {
          this.$emit("reloadTask");
          self.show = false;
          self.$toasted.success("Successfully Update Company Status");
          self.statusId = null;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
