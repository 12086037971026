<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container class="container" fluid style="border-radius: 30px" v-if="!overlay">
      <v-card elevation="0" style="border-radius: 30px">
        <v-card-title>
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          <div style="width: 50px"></div>
          <AddProduct style="display: inline-block" @reloadTask="initialData" />
        </v-card-title>
        <v-data-table :headers="headers" :items="items" :items-per-page="15" item-key="idStudent" class="table-content">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>
          <template v-slot:[`item.imagePath`]="{ item }">
            <v-avatar size="100">
              <img :src="`${$store.state.apiUrl}/${item.imagePath}`" alt="John" />
            </v-avatar>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddProduct from "./../AddProduct.vue";
export default {
  props: {
    companyId: Number,
  },
  components: {
    AddProduct,
  },
  data: () => ({
    overlay: true,
    items: [],
    search: "",
    headers: [
      { text: "index", value: "index" },
      { text: "image", value: "imagePath" },
      { text: "title", value: "title" },
      { text: "description", value: "description" },
    ],
  }),
  mounted() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.$http
        .get(`${self.$store.state.apiUrl}/companyProducts/${self.companyId}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.items = res.data;
          self.overlay = false;
          console.log(res.data);
        });
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.items.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.title.toLowerCase().includes(v));
        });
      } else {
        return this.items;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
