<template>
  <div>
    <v-dialog v-model="show" persistent max-width="1000px" width="800px">
      <v-card v-if="show">
        <v-toolbar dark color="primary">
          <v-btn icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ items.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-img :src="`${$store.state.apiUrl}/${items.imagePath}`"></v-img>

          <div style="margin-top: 10px"></div>
          <h2>{{ items.title }}</h2>
          <div style="margin-top: 10px"></div>
          <v-divider class="mx-4"></v-divider>
          <div style="margin-top: 10px"></div>
          <p>{{ items.description }}</p>

          <div style="display: inline-block; margin: 10px"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    items: Object,
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss" scoped></style>
