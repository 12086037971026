<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-list color="transparent">
      <v-list-item v-for="(loc, index) in items" :key="index">
        <v-list-item-avatar>
          <v-icon>mdi-map-marker-radius</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Location : {{ loc.externalId }}</v-list-item-title>
          <v-list-item-subtitle>Status : {{ loc.locationStatusId | status }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    companyId: Number,
  },
  data: () => ({
    items: [],
    overlay: true,
  }),
  created() {
    this.initData();
  },
  methods: {
    initData() {
      let self = this;
      self.$http
        .get(`${self.$store.state.apiUrl}/companyLocation/${self.companyId}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.items = res.data;
          self.overlay = false;
        });
    },
  },
  filters: {
    status(id) {
      if (id == 1) {
        return "Pending";
      } else if (id == 2) {
        return "Accepted";
      } else {
        return "Rejected";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
