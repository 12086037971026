<template>
  <!-- <div id="map">
    
  </div> -->
  <div style="margin-top: 10px">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container fluid>
      <AcceptLocation :show="show" @accept="acceptLocation" @closeDialog="show = false" />
      <CompanyProducts :show="showCompanyProduct" @closeDialog="showCompanyProduct = false" :items="singleProduct" />

      <v-row>
        <v-col cols="2">
          <v-sheet rounded="lg" class="glass-container" style="overflow: hidden; overflow-y: scroll; height: 93vh; background-color: #fff8f3">
            <v-text-field style="margin: 10px" v-model="search" outlined dense append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            <v-divider class="my-2"></v-divider>

            <v-list three-line color="transparent">
              <v-list-item :key="index" v-for="(item, index) in ItemsFilter" @click="selectLocation(item)">
                <v-list-item-avatar>
                  <v-img :src="`${$store.state.apiUrl}/${item.companyLogo}`"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ item.companyName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.categoryName }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-sheet>
        </v-col>

        <v-col>
          <v-sheet rounded="lg" id="map" class="glass-bar" style="height: 93vh"></v-sheet>

          <!-- <v-sheet rounded="lg" class="glass-container" style="margin-top: 10px; padding: 10px; position: relative; bottom: 250px" v-if="showProducts && companyProducts.length > 0">
            <div style="padding: 10px">
              <h3>Company Products</h3>
            </div>
            <Flicking :options="{ circular: true, bound: true, align: 'prev' }">
              <v-card style="display: inline-block; margin: 10px; overflow: hidden; overflow-x: scroll" max-width="250" v-for="(item, i) in companyProducts" :key="i" @click="selectProduct(item)">
                <v-img height="150" :src="`${$store.state.apiUrl}/${item.imagePath}`"></v-img>
              </v-card>
            </Flicking>
          </v-sheet> -->
          <!-- <v-card-title>{{ item.title }}</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <div>{{ item.description }}</div>
                </v-card-text> -->

          <v-sheet rounded="lg" class="glass-container" style="width: 340px; position: fixed; top: 80px">
            <div v-if="companyDetails != null" style="padding: 10px">
              <v-avatar class="hidden-sm-and-down" color="#eeeeee" size="100" style="margin: auto; display: block">
                <img :src="`${$store.state.apiUrl}/${companyDetails.companyLogo}`" alt="" width="100" />
              </v-avatar>
              <div style="padding: 20px">
                <h3>Company Name : {{ companyDetails.companyName }}</h3>
                <h3>Company Phone : {{ companyDetails.phone }}</h3>
                <p>Company Details : {{ companyDetails.description }}</p>
                <v-divider></v-divider>
                <div style="height: 10px"></div>
                <v-row>
                  <v-col cols="3" style="text-align: center">
                    <v-icon color="blue" @click="openInNewTab(companyDetails.facebookUrl)">mdi-facebook</v-icon>
                  </v-col>
                  <v-col cols="3" style="text-align: center"> <v-icon color="red" @click="openInNewTab(companyDetails.instagramUrl)">mdi-instagram</v-icon></v-col>
                  <v-col cols="3" style="text-align: center"> <v-icon color="blue" @click="openInNewTab(companyDetails.xUrl)">mdi-twitter</v-icon></v-col>
                  <v-col cols="3" style="text-align: center"> <v-icon color="success" @click="openInNewTab(companyDetails.companyUrl)">mdi-web</v-icon></v-col>
                </v-row>
                <div style="height: 10px"></div>
                <v-divider></v-divider>

                <div style="margin: 10px auto; text-align: center">
                  <v-btn color="info" @click="getCompanyProducts">
                    <v-icon left> mdi-pencil </v-icon>
                    Company Products
                  </v-btn>
                </div>
                <v-divider></v-divider>
                <div v-if="showProducts" style="overflow: hidden; overflow-y: scroll; height: 550px">
                  <div v-if="companyProducts.length > 0">
                    <v-card
                      style="display: inline-block; margin: 10px; overflow: hidden; overflow-x: scroll"
                      max-width="250"
                      v-for="(item, i) in companyProducts"
                      :key="i"
                      @click="selectProduct(item)"
                    >
                      <v-img height="150" :src="`${$store.state.apiUrl}/${item.imagePath}`"></v-img>
                    </v-card>
                  </div>
                  <div v-if="showProducts && companyProducts.length == 0"></div>
                </div>
              </div>
            </div>
          </v-sheet>
        </v-col>
        <!-- <v-col cols="2">
          <v-sheet rounded="lg" class="glass-container"> -->
        <!-- <div>{{ companyDetails }}</div> -->
        <!-- <div v-if="companyDetails != null" style="padding: 10px">
              <v-avatar class="hidden-sm-and-down" color="#eeeeee" size="100" style="margin: auto; display: block">
                <img :src="`${$store.state.apiUrl}/${companyDetails.companyLogo}`" alt="" width="100" />
              </v-avatar>
              <div style="padding: 20px">
                <h3>Company Name : {{ companyDetails.companyName }}</h3>
                <h3>Company Phone : {{ companyDetails.phone }}</h3>
                <p>Company Details : {{ companyDetails.description }}</p>
                <v-divider></v-divider>
                <div style="height: 10px"></div>
                <v-row>
                  <v-col cols="3" style="text-align: center">
                    <v-icon color="blue" @click="openInNewTab(companyDetails.facebookUrl)">mdi-facebook</v-icon>
                  </v-col>
                  <v-col cols="3" style="text-align: center"> <v-icon color="red" @click="openInNewTab(companyDetails.instagramUrl)">mdi-instagram</v-icon></v-col>
                  <v-col cols="3" style="text-align: center"> <v-icon color="blue" @click="openInNewTab(companyDetails.xUrl)">mdi-twitter</v-icon></v-col>
                  <v-col cols="3" style="text-align: center"> <v-icon color="success" @click="openInNewTab(companyDetails.companyUrl)">mdi-web</v-icon></v-col>
                </v-row>
                <div style="height: 10px"></div>
                <v-divider></v-divider> -->
        <!-- <div style="margin: 10px auto; text-align: center">
                  <v-btn color="info" @click="getCompanyProducts">
                    <v-icon left> mdi-pencil </v-icon>
                    Company Products
                  </v-btn>
                </div> -->
        <!-- </div>
            </div>
          </v-sheet>
        </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getVenue, showVenue, E_SDK_EVENT } from "@mappedin/mappedin-js";
import "@mappedin/mappedin-js/lib/mappedin.css";
import AcceptLocation from "./../components/companyHome/AcceptLocation.vue";
import CompanyProducts from "./../components/CompanyProducts.vue";
export default {
  components: {
    AcceptLocation,
    CompanyProducts,
  },
  data: () => ({
    locations: [],
    show: false,
    search: "",
    mapView: null,
    companyDetails: null,
    locationDetails: null,
    overlay: true,
    showProducts: false,
    companyProducts: [],
    showCompanyProduct: false,
    singleProduct: null,
    savedCompanyLocation: null,
  }),
  mounted() {
    let self = this;
    this.init(self.$store.state.options);
  },
  methods: {
    getCompanyProducts() {
      let self = this;
      self.$http
        .get(`${self.$store.state.apiUrl}/companyProducts/${self.companyDetails.companyId}`)
        .then((res) => {
          self.companyProducts = res.data;
          self.showProducts = true;
        })
        .catch((e) => {
          self.$toasted.error("Error Fetching Products");
          console.log(e);
        });
    },
    selectProduct(product) {
      let self = this;
      console.log(product);
      self.singleProduct = product;
      self.showCompanyProduct = true;
    },
    acceptLocation() {
      let self = this;

      self.$http
        .post(
          `${self.$store.state.apiUrl}/addCompanyLocation`,
          { companyId: self.$store.state.userData.idCompany, externalId: self.locationDetails.id, locationStatusId: 1, approvedBy: 0 },
          { headers: { Authorization: `Bearer ${self.$store.state.user}` } }
        )
        .then((res) => {
          self.$toasted.success("Successfully Send To Reserved Location");
          console.log(res.data);
          self.show = false;
          self.init(self.$store.state.options);
        })
        .catch((e) => {
          self.$toasted.error("Error Reserved Location");
        });
    },

    openInNewTab(url) {
      window.open(url, "_blank").focus();
    },

    async init(options) {
      let self = this;
      try {
        const venue = await getVenue(options);
        const mapView = await showVenue(document.getElementById("map"), venue, {
          multiBufferRendering: true,
          outdoorView: {
            enabled: true,
          },
        });
        const reservedLocations = await self.$http.get(`${self.$store.state.apiUrl}/companyLocations`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } });

        self.locations = reservedLocations.data.filter((loc) => loc.locationStatusId == 2);
        self.mapView = mapView;

        mapView.venue.locations.forEach((location) => {
          // location.canvasBounds;
          let foundLocation = self.locations.find((loc) => location?.id == loc.externalId);
          if (foundLocation) {
            location.name = foundLocation.companyName;

            foundLocation.locationStatusId == 1 ? mapView.setPolygonColor(location.polygons[0], "#ff6b6b") : mapView.setPolygonColor(location.polygons[0], "#0093e9");
          } else {
            if (location?.type != "amenities" && location?.name) {
              location.name = "Not Reserved";
              // location.type != "amenities" ? mapView.setPolygonColor(location.polygons[0], "#83c5be") : "";
            }
          }
          // console.log(foundLocation);
          // if (location?.name) {
          // location.name = location.id;
          // if (location.name == "White Barn") {
          //   location.name = ` Tamkeen`;
          //   console.log(location.polygons);
          //   mapView.setPolygonColor(location.polygons[0], "#7C2979");
          // }
          // }
        });
        mapView.addInteractivePolygonsForAllLocations();

        mapView.FlatLabels.labelAllLocations({
          appearance: {
            font: "Georgia",
            fontSize: 12,
            color: "#1c1c43",
          },
        });

        self.overlay = false;

        // mapView.FloatingLabels.labelAllLocations({ interactive: true });

        mapView.on(E_SDK_EVENT.CLICK, ({ polygons, floatingLabels }) => {
          // console.log(polygons);
          let foundLocation = reservedLocations.data.find((loc) => polygons[0]?.locations[0]?.id == loc.externalId);

          if (self.$store.state.userData != null) {
            let alreadyHaveSpot = reservedLocations.data.find((loc) => loc.companyId == self.$store.state.userData.idCompany);
            if (!alreadyHaveSpot && !foundLocation && polygons[0]?.locations[0].type != "amenities" && polygons[0]?.locations[0] && polygons.length > 0) {
              if (self.$store.state.userData.statusId != 2) {
                self.$toasted.error("You Must be accepted first");
              } else {
                mapView.clearAllPolygonColors();
                // this.selectLocation(polygons[0]?.locations[0]);
                self.show = true;
                self.locationDetails = polygons[0]?.locations[0];
              }
            } else if (
              polygons[0]?.locations[0].type != "amenities" &&
              polygons[0]?.locations[0] &&
              polygons.length > 0 &&
              self.isSelectSpot(reservedLocations.data, polygons[0]?.locations[0]).active
            ) {
              // mapView.clearAllPolygonColors();
              mapView.setPolygonColor(polygons[0], "#0093e9");
              this.selectLocation(self.isSelectSpot(reservedLocations.data, polygons[0]?.locations[0]).details);
            } else {
              self.companyDetails = null;
              self.showProducts = false;
            }
          } else {
            if (polygons[0]?.locations[0] && polygons[0]?.locations[0].type != "amenities" && self.isSelectSpot(reservedLocations.data, polygons[0]?.locations[0]).active) {
              // console.log(floatingLabels);
              // mapView.clearAllPolygonColors();
              mapView.setPolygonColor(polygons[0], "#0093e9");
              this.selectLocation(self.isSelectSpot(reservedLocations.data, polygons[0]?.locations[0]).details);
              //self.getCompanyProducts();
            } else {
              self.companyDetails = null;
              self.showProducts = false;
            }

            // if (polygons.length > 0 && polygons[0]?.locations[0].type != "amenities") {
            //   // mapView.clearAllPolygonColors();
            //   mapView.setPolygonColor(polygons[0], "#0093e9");
            //   console.log(mapView.getPrimaryLocationForPolygon(polygons[0]));

            //   //   const location = mapView.getPrimaryLocationForPolygon(polygons[0]);

            //   //   if (!location) return;

            //   //   const { name } = location;

            //   //   const markerTemplate = `<div class="marker"><p>${name}</p></div>`;

            //   //   mapView.Markers.add(polygons[0].entrances[0], markerTemplate);
            // } else {
            //   // mapView.clearAllPolygonColors();
            // }
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    isSelectSpot(locations, spot) {
      let isAvailable = locations.find((loc) => loc.externalId == spot.id);
      if (isAvailable) {
        return {
          active: true,
          details: isAvailable,
        };
      } else {
        return {
          active: false,
          details: null,
        };
      }
    },
    selectLocation(selectedLocation) {
      let self = this;
      self.companyDetails = selectedLocation;
      self.showProducts = false;
      self.companyProducts = [];
      // self.mapView.clearAllPolygonColors();
      self.mapView.venue.locations.forEach((location) => {
        // location.canvasBounds;

        if (location?.name) {
          if (location.id == selectedLocation.externalId && location.type != "amenities") {
            console.log(selectedLocation);
            if (self.savedCompanyLocation != null) {
              console.log(self.savedCompanyLocation);
              let previousLocation = self.mapView.venue.locations.find((loc) => self.savedCompanyLocation.externalId == loc.id);
              if (previousLocation) {
                self.mapView.setPolygonColor(previousLocation.polygons[0], "#0093e9");
              }
            }
            self.mapView.setPolygonColor(location.polygons[0], "#ffe66d");
          }
        }
      });
      self.savedCompanyLocation = selectedLocation;
      // self.getCompanyProducts();
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.locations.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.companyName.toLowerCase().includes(v));
        });
      } else {
        return this.locations;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
