<template>
  <div style="padding: 20px; background-color: #fff8f3">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row v-if="!overlay">
      <v-col cols="6">
        <p>Company Name*</p>
        <v-text-field class="field-register-information" :rules="studentRequiredFormRules" hide-details outlined v-model="companyDetails.companyName" label="Enter your company name"></v-text-field>
        <div style="height: 15px"></div>
        <p>Registration Number*</p>
        <v-text-field
          class="field-register-information"
          :rules="studentRequiredFormRules"
          hide-details
          outlined
          v-model="companyDetails.registrationNumber"
          label="Enter your registration number "
        ></v-text-field>
        <div style="height: 15px"></div>
        <p>Email*</p>
        <v-text-field class="field-register-information" :rules="studentRequiredFormRules" hide-details outlined v-model="companyDetails.mail" label="Enter your company email"></v-text-field>

        <div style="height: 15px"></div>
        <p>Phone Number*</p>
        <v-text-field
          class="field-register-information"
          type="number"
          hide-details
          outlined
          :rules="studentRequiredFormRules"
          v-model="companyDetails.phone"
          label="Enter your company phone"
        ></v-text-field>
        <div style="height: 15px"></div>
        <p>Description*</p>
        <v-textarea class="field-register-information" outlined :rules="studentRequiredFormRules" hide-details v-model="companyDetails.description" label="Enter your company description"></v-textarea>
      </v-col>
      <v-col cols="6">
        <p>Category*</p>

        <v-autocomplete
          v-model="companyDetails.categoryId"
          outlined
          class="field-register-information"
          label="Select a category"
          item-text="categoryName"
          item-value="idCategory"
          :rules="studentRequiredFormRules"
          :items="categories"
          item-color="accent"
          hide-details
        ></v-autocomplete>
        <div style="height: 15px"></div>
        <p>Website*</p>
        <v-text-field class="field-register-information" :rules="studentRequiredFormRules" hide-details outlined v-model="companyDetails.companyUrl" label="Enter the company website"></v-text-field>
        <div style="height: 15px"></div>

        <p>Facebook*</p>
        <v-text-field class="field-register-information" :rules="studentRequiredFormRules" hide-details outlined v-model="companyDetails.facebookUrl" label="Enter the company facebook"></v-text-field>
        <div style="height: 15px"></div>

        <p>Instagram*</p>
        <v-text-field
          class="field-register-information"
          :rules="studentRequiredFormRules"
          hide-details
          outlined
          v-model="companyDetails.instagramUrl"
          label="Enter the company instagram"
        ></v-text-field>
        <div style="height: 15px"></div>

        <p>X (Twitter)*</p>
        <v-text-field class="field-register-information" :rules="studentRequiredFormRules" hide-details outlined v-model="companyDetails.xUrl" label="Enter the company x (twitter)"></v-text-field>
        <div style="height: 15px"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    companyId: Number,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "Field is Required"],
    categories: [],
    companyDetails: null,
    overlay: true,
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/categories`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/company/${self.companyId}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((categories, company) => {
            self.categories = categories.data;
            self.companyDetails = company.data;
            self.overlay = false;
          })
        )
        .catch((err) => {
          console.log(err);
          self.overlay = false;
          this.$toasted.error("Error While Fetching the data");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
