<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add New Product</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <VueFileAgent
            style="width: 30%; display: inline-block"
            ref="vueFileAgent"
            :theme="'grid'"
            :multiple="false"
            :deletable="true"
            :meta="true"
            :accept="'image/*'"
            :maxSize="'10MB'"
            :maxFiles="1"
            :helpText="'Choose image'"
            :errorText="{
              type: 'Invalid file type. Only images or zip Allowed',
              size: 'Files should not exceed 10MB in size',
            }"
            @select="filesSelected($event)"
            @beforedelete="onBeforeDelete($event)"
            @delete="fileDeleted($event)"
            v-model="fileRecords"
          ></VueFileAgent>
          <div style="margin-top: 10px"></div>
          <v-btn text color="info" @click="uploadFiles()" :disabled="!fileRecordsForUpload.length"> upload image </v-btn>
          <!-- <button :disabled="!fileRecordsForUpload.length" @click="uploadFiles()">Upload {{ fileRecordsForUpload.length }} files</button> -->
          <div style="margin-top: 10px"></div>
          <v-text-field :rules="studentRequiredFormRules" outlined v-model="title" label="Product Title"></v-text-field>

          <v-textarea outlined :rules="studentRequiredFormRules" v-model="description" label="Description"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab depressed small color="info" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>Add Product</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    sectionInstallments: Array,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "This Field is Required"],
    show: false,
    title: "",
    description: "",
    photoPath: "",
    fileRecords: [],
    uploadUrl: "https://map.smartuni.app/api/uploadImage",
    uploadHeaders: { "X-Test-Header": "vue-file-agent" },
    fileRecordsForUpload: [],
    productImage: "",
  }),

  methods: {
    saveData() {
      let self = this;

      let data = {
        title: self.title,
        description: self.description,
        imagePath: self.productImage,
        companyId: self.$store.state.userData.idCompany,
      };

      self.$http.post(`${self.$store.state.apiUrl}/addProduct`, data).then((res) => {
        this.$emit("reloadTask");
        console.log(res.data);
        self.show = false;
        self.productImage = "";
        self.title = "";
        self.description = "";
        self.fileRecordsForUpload = [];
        self.fileRecords = [];
        self.$toasted.success("Successfully added Product");
      });
    },
    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload).then((res) => {
        this.productImage = res[0].data[0].imagePath;
        console.log(this.productImage);
        console.log(res[0].data[0].imagePath);
      });
      this.fileRecordsForUpload = [];
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
