import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  // rtl: true,
  theme: {
    themes: {
      light: {
        primary: "#0093e9",
        label: "#83c5be",
        secondary: "#d6e0f0",
        background: "#edf6f9",
        accent: "#1b998b",
        error: "#ff6b6b",
        dark: "#1e1e1e",
        info: "#2189e8",
        stepper: "#E6F8F7",
        success: "#1b998b",
        warning: "#f09609",
        edit: "#f09609",
      },
      dark: {
        primary: "#363636",
        secondary: "#d6e0f0",
        background: "#fff",
        accent: "#006a71",
        error: "#ff6b6b",
        info: "#2196F3",
        dark: "#363636",
        stepper: "#ebffeb",
        success: "#1b998b",
        warning: "#ffe66d",
      },
    },
  },
});
