<template>
  <div style="padding: 20px; text-align: center">
    <v-card max-width="675" class="mx-auto glass-container" color="transparent">
      <v-img :src="require('./../assets/bac2.jpeg')" height="300px" dark>
        <v-row class="fill-height">
          <!-- <v-card-title>
            <v-btn dark icon>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn dark icon class="mr-4">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn dark icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-card-title> -->

          <v-spacer></v-spacer>

          <!-- <v-card-title class="white--text pl-12 pt-12">
            <div class="text-h4 pl-12 pt-12">Ali Conners</div>
          </v-card-title> -->
        </v-row>
      </v-img>

      <v-list two-line>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="info"> mdi-phone </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>(+964) 7902911995</v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <v-icon>mdi-message-text</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="info"> mdi-email </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>interior.fairs@yahoo.com</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-action></v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>iraqifairs@mot.gov.iq</v-list-item-title>
            <!-- <v-list-item-subtitle>Work</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="info"> mdi-map-marker </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Al-Mansor, Baghdad</v-list-item-title>
            <v-list-item-subtitle> International Fairs ground</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
