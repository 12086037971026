<template>
  <div style="padding: 20px">
    <v-stepper v-model="e1" color="primary" class="stepper-register" style="border-radius: 20px" elevation="0">
      <v-row>
        <!-- <v-col cols="3" style="background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%)"> -->
        <v-col
          cols="3"
          style="
            background: rgba(255, 255, 255, 0.32) !important;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
            backdrop-filter: blur(10px) !important;
            border: 1px solid rgba(255, 255, 255, 1) !important;
          "
        >
          <div>
            <v-stepper-header style="display: block; margin: 10px; box-shadow: none">
              <v-stepper-step color="background" :complete="e1 > 1" step="1"> Company Information </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step color="background" :complete="e1 > 2" step="2"> Company Photos </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3" color="background"> Company Password </v-stepper-step>
            </v-stepper-header>
          </div>
        </v-col>
        <v-col cols="9">
          <v-stepper-items
            style="
              background: rgba(255, 255, 255, 0.52) !important;
              box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
              backdrop-filter: blur(10px) !important;
              border: 1px solid rgba(255, 255, 255, 1) !important;
              border-radius: 16px !important;
            "
          >
            <v-stepper-content step="1">
              <v-row>
                <v-col cols="6">
                  <p>Company Name*</p>
                  <v-text-field class="field-register-information" :rules="studentRequiredFormRules" hide-details outlined v-model="companyName" label="Enter your company name"></v-text-field>
                  <div style="height: 15px"></div>
                  <p>Registration Number*</p>
                  <v-text-field
                    class="field-register-information"
                    :rules="studentRequiredFormRules"
                    hide-details
                    outlined
                    v-model="registrationNumber"
                    label="Enter your registration number "
                  ></v-text-field>
                  <div style="height: 15px"></div>
                  <p>Email*</p>
                  <v-text-field class="field-register-information" :rules="studentRequiredFormRules" hide-details outlined v-model="mail" label="Enter your company email"></v-text-field>

                  <div style="height: 15px"></div>
                  <p>Phone Number*</p>
                  <v-text-field
                    class="field-register-information"
                    type="number"
                    hide-details
                    outlined
                    :rules="studentRequiredFormRules"
                    v-model="phone"
                    label="Enter your company phone"
                  ></v-text-field>
                  <div style="height: 15px"></div>
                  <p>Description*</p>
                  <v-textarea class="field-register-information" outlined :rules="studentRequiredFormRules" hide-details v-model="description" label="Enter your company description"></v-textarea>
                </v-col>
                <v-col cols="6">
                  <p>Category*</p>

                  <v-autocomplete
                    v-model="categoryId"
                    outlined
                    class="field-register-information"
                    label="Select a category"
                    item-text="categoryName"
                    item-value="idCategory"
                    :rules="studentRequiredFormRules"
                    :items="categories"
                    item-color="accent"
                    hide-details
                  ></v-autocomplete>
                  <div style="height: 15px"></div>
                  <p>Type*</p>
                  <v-autocomplete
                    v-model="workTypeId"
                    outlined
                    class="field-register-information"
                    label="Select a Type"
                    item-text="name"
                    item-value="id"
                    :rules="studentRequiredFormRules"
                    :items="$store.state.workTypes"
                    item-color="accent"
                    hide-details
                  ></v-autocomplete>
                  <div style="height: 15px"></div>
                  <p>Website*</p>
                  <v-text-field class="field-register-information" :rules="studentRequiredFormRules" hide-details outlined v-model="companyUrl" label="Enter the company website"></v-text-field>
                  <div style="height: 15px"></div>

                  <p>Facebook*</p>
                  <v-text-field class="field-register-information" :rules="studentRequiredFormRules" hide-details outlined v-model="facebookUrl" label="Enter the company facebook"></v-text-field>
                  <div style="height: 15px"></div>

                  <p>Instagram*</p>
                  <v-text-field class="field-register-information" :rules="studentRequiredFormRules" hide-details outlined v-model="instagramUrl" label="Enter the company instagram"></v-text-field>
                  <div style="height: 15px"></div>

                  <p>X (Twitter)*</p>
                  <v-text-field class="field-register-information" :rules="studentRequiredFormRules" hide-details outlined v-model="xUrl" label="Enter the company x (twitter)"></v-text-field>
                  <div style="height: 15px"></div>
                </v-col>
              </v-row>
              <!-- <v-card class="mb-12" color="grey lighten-1" height="200px"></v-card> -->

              <div style="height: 15px"></div>
              <v-btn color="info" @click="goToNext(2)"> Next </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-row>
                <v-col cols="6" style="text-align: center">
                  <VueFileAgent
                    style="width: 30%; display: inline-block"
                    ref="vueFileAgent"
                    :theme="'grid'"
                    :multiple="false"
                    :deletable="true"
                    :meta="true"
                    :accept="'image/*'"
                    :maxSize="'10MB'"
                    :maxFiles="1"
                    :helpText="'Choose image'"
                    :errorText="{
                      type: 'Invalid file type. Only images or zip Allowed',
                      size: 'Files should not exceed 10MB in size',
                    }"
                    @select="filesSelected($event)"
                    @beforedelete="onBeforeDelete($event)"
                    @delete="fileDeleted($event)"
                    v-model="fileRecords"
                  ></VueFileAgent>
                  <div style="height: 15px"></div>
                  <v-btn text @click="uploadFiles()" :disabled="!fileRecordsForUpload.length"> upload Logo </v-btn>
                </v-col>
                <v-col cols="6">
                  <VueFileAgent
                    style="width: 30%; display: inline-block"
                    ref="vueFileAgent"
                    :theme="'grid'"
                    :multiple="false"
                    :deletable="true"
                    :meta="true"
                    :accept="'image/*,.pdf'"
                    :maxSize="'20MB'"
                    :maxFiles="1"
                    :helpText="'Choose image'"
                    :errorText="{
                      type: 'Invalid file type. Only images or zip Allowed',
                      size: 'Files should not exceed 10MB in size',
                    }"
                    @select="filesSelectedDocument($event)"
                    @beforedelete="onBeforeDeleteDocument($event)"
                    @delete="fileDeletedDocument($event)"
                    v-model="fileRecordsDocument"
                  ></VueFileAgent>
                  <div style="height: 15px"></div>
                  <v-btn text @click="uploadFilesDocument()" :disabled="!fileRecordsForUploadDocument.length"> upload document </v-btn>
                </v-col>
              </v-row>

              <!-- <button :disabled="!fileRecordsForUpload.length" @click="uploadFiles()">Upload {{ fileRecordsForUpload.length }} files</button> -->
              <div></div>
              <v-btn color="info" @click="goToNext(3)"> Next </v-btn>

              <!-- <v-btn text> Previews </v-btn> -->
            </v-stepper-content>

            <v-stepper-content step="3">
              <p>Create Password*</p>
              <v-text-field
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                :rules="studentRequiredFormRules"
                outlined
                @click:append="show1 = !show1"
                v-model="password"
                label="Write your Password"
              ></v-text-field>
              <p>Confirm Password*</p>
              <v-text-field
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                :rules="studentRequiredFormRules"
                outlined
                @click:append="show1 = !show1"
                v-model="confirmPassword"
                label="Confirm Password"
              ></v-text-field>
              <v-btn color="info" @click="registerCompany"> Register </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-col>
      </v-row>
    </v-stepper>
  </div>
</template>

<script>
export default {
  data: () => ({
    e1: 1,
    // studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    studentRequiredFormRules: [(v) => !!v || "Field is Required"],
    fileRecords: [],
    uploadUrl: "https://map.smartuni.app/api/uploadLogo",
    uploadHeaders: { "X-Test-Header": "vue-file-agent" },
    fileRecordsForUpload: [],
    logoUploaded: "",

    fileRecordsDocument: [],
    uploadUrlDocument: "https://map.smartuni.app/api/uploadDocument",
    uploadHeadersDocument: { "X-Test-Header": "vue-file-agent" },
    fileRecordsForUploadDocument: [],
    documentUploaded: "",

    show1: false,

    companyName: "",
    companyLogo: "",
    categoryId: "",
    phone: "",
    description: "",
    registrationNumber: "",
    statusId: "",
    companyUrl: "",
    password: "",
    confirmPassword: "",
    mail: "",
    workTypeId: "",
    categories: [],
    instagramUrl: "",
    facebookUrl: "",
    xUrl: "",
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.$http
        .get(`${self.$store.state.apiUrl}/categories`)
        .then((res) => {
          self.categories = res.data;
        })
        .catch((e) => console.log(e));
    },
    goToNext(page) {
      let self = this;
      // self.e1 = 2;
      if (page == 2) {
        if (self.companyName && self.phone && self.description && self.registrationNumber && self.companyUrl && self.mail && self.xUrl && self.facebookUrl && self.instagramUrl && self.workTypeId) {
          self.e1 = page;
        } else {
          this.$toasted.error(" Please Fill All the Fields to move to the next page");
        }
      } else if (page == 3) {
        if (self.companyName && self.phone && self.description) {
          self.e1 = page;
        } else {
          this.$toasted.error(" Please Fill All the Fields to move to the next page");
        }
      }
    },
    uploadFiles() {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload).then((res) => {
        this.logoUploaded = res[0].data[0].imagePath;
      });
      this.fileRecordsForUpload = [];
    },
    deleteUploadedFile(fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },
    filesSelected(fileRecordsNewlySelected) {
      try {
        var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
        this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
      } catch (e) {
        console.log(e);
      }
    },
    onBeforeDelete(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },

    uploadFilesDocument() {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(this.uploadUrlDocument, this.uploadHeaders, this.fileRecordsForUploadDocument).then((res) => {
        this.documentUploaded = res[0].data[0].imagePath;
      });
      this.fileRecordsForUploadDocument = [];
    },
    deleteUploadedFile(fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrlDocument, this.uploadHeaders, fileRecord);
    },
    filesSelectedDocument(fileRecordsNewlySelected) {
      try {
        var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
        this.fileRecordsForUploadDocument = this.fileRecordsForUploadDocument.concat(validFileRecords);
      } catch (e) {
        console.log(e);
      }
    },
    onBeforeDeleteDocument(fileRecord) {
      var i = this.fileRecordsForUploadDocument.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUploadDocument.splice(i, 1);
        var k = this.fileRecordsDocument.indexOf(fileRecord);
        if (k !== -1) this.fileRecordsDocument.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeletedDocument(fileRecord) {
      var i = this.fileRecordsForUploadDocument.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUploadDocument.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },

    registerCompany() {
      let self = this;
      let data = {
        companyName: self.companyName,
        companyLogo: self.logoUploaded,
        password: self.password,
        categoryId: self.categoryId,
        phone: self.phone,
        description: self.description,
        mail: self.mail,
        registrationNumber: self.registrationNumber,
        companyUrl: self.companyUrl,
        statusId: 1,
        facebookUrl: self.facebookUrl,
        instagramUrl: self.instagramUrl,
        xUrl: self.xUrl,
        workTypeId: self.workTypeId,
        documentUpload: self.documentUploaded,
      };
      self.$http
        .post(`${self.$store.state.apiUrl}/addCompany`, data)
        .then((res) => {
          self.$toasted.success("Successfully added company Please Wait Until Confirmation");
          self.$router.push("/login").catch((err) => {});
        })
        .catch((err) => {
          self.$toasted.error("Error while adding company Please Try Again");
        });
    },
  },
};
</script>

<style>
.theme--light.v-stepper {
  background: transparent !important;
}
.stepper-register .v-stepper__label {
  /* color: #fafafa !important; */
  font-weight: bold !important;
}
.stepper-register .v-stepper__step--active .v-stepper__label {
  /* color: #ffffff !important; */
  font-weight: bold !important;
}
.theme--light.v-stepper .v-stepper__step__step {
  color: #0e0e0e !important;
}

.stepper-register .stepper-content-fields {
  max-width: 40% !important;
}
.field-register-information {
  background: #fff;
}

.v-stepper__step__step .v-icon.v-icon {
  color: green !important;
}

.v-text-field .v-input__control {
  border-radius: 10px !important;
}

.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: #c0baba;
}

.theme--light.v-input {
  border-radius: 14px !important;
}
</style>
