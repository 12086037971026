<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="590">
      <v-card class="glass-container">
        <v-card-title class="text-h5"> Are You sure to reserve this location? </v-card-title>
        <v-card-text>
          <h3>Location Name : D-231</h3>
          <h3>Location Price : 130$</h3>
          <h3>Site Area : 90 M</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeDialog()"> Cancel </v-btn>
          <v-btn color="green darken-1" text @click="acceptLocation()"> Accept </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    acceptLocation() {
      this.$emit("accept");
    },
  },
};
</script>

<style lang="scss" scoped></style>
