<template>
  <div style="margin: 10px">
    <v-row>
      <v-col cols="4">
        <v-card class="mx-auto" tile style="margin: 10px">
          <v-list dense>
            <v-subheader>Company Menu</v-subheader>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 16px">{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <div class="pa-2">
              <div style="height: 40px"></div>
              <v-btn block color="error" class="error-button" @click="logout"> Logout </v-btn>
            </div>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card class="mx-auto" tile style="margin: 10px">
          <Product :companyId="$store.state.userData.idCompany" v-if="selectedItem == 3" />
          <Documents :company="$store.state.userData" v-if="selectedItem == 2" />
          <Profile :companyId="$store.state.userData.idCompany" v-if="selectedItem == 0" />
          <Locations :companyId="$store.state.userData.idCompany" v-if="selectedItem == 1" />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Product from "./../components/companyHome/Product.vue";
import Documents from "./../components/companyHome/Documents.vue";
import Profile from "./../components/companyHome/Profile.vue";
import Locations from "./../components/companyHome/Locations.vue";
export default {
  components: {
    Product,
    Locations,
    Profile,
    Documents,
  },
  data: () => ({
    selectedItem: 0,
    items: [
      { text: "Profile", icon: "mdi-domain" },
      { text: "Locations", icon: "mdi-map-marker-multiple-outline" },
      { text: "Documents", icon: "mdi-text-box-multiple-outline" },
      { text: "Products", icon: "mdi-package-variant-closed" },
    ],
  }),
  created() {},
  methods: {
    logout() {
      localStorage.removeItem("company");
      this.$router.push("/").catch((e) => {});
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped></style>
