<template>
  <div style="padding: 20px">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="6">
        <h3>Company Logo</h3>
        <div style="height: 20px"></div>
        <v-img :src="`${$store.state.apiUrl}/${company.companyLogo}`" width="300" height="300" contain></v-img>
      </v-col>
      <v-col cols="6">
        <h3>Company File</h3>
        <div style="height: 20px"></div>

        <v-btn color="success" @click="openInNewTab(file.filePath)" v-if="file != null">
          <v-icon left> mdi-file-pdf-box </v-icon>
          Open Pdf
        </v-btn>
        <div v-else>
          <h3>No File Found</h3>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    company: Object,
  },
  data: () => ({
    file: null,
    overlay: true,
  }),
  created() {
    this.initData();
  },
  methods: {
    initData() {
      let self = this;
      self.$http
        .get(`${self.$store.state.apiUrl}/companyFile/${self.company.idCompany}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.file = res.data;
          self.overlay = false;
        })
        .catch((err) => {
          console.log(err);
          self.file = null;
          self.overlay = false;
        });
    },
    openInNewTab(url) {
      window.open(`${this.$store.state.apiUrl}/${url}`, "_blank").focus();
    },
  },
  filters: {
    status(id) {
      if (id == 1) {
        return "Pending";
      } else if (id == 2) {
        return "Accepted";
      } else {
        return "Rejected";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
