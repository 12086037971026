<template>
  <div style="background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-row style="padding-top: 10px">
      <v-col cols="2">
        <v-sheet rounded="lg" class="glass-container" style="overflow: hidden; overflow-y: scroll; height: 90vh">
          <v-text-field style="margin: 10px" v-model="search" outlined dense append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          <v-divider class="my-2"></v-divider>
          <v-list three-line color="transparent">
            <v-list-item :key="index" v-for="(item, index) in ItemsFilter" @click="selectCompany(item)">
              <v-list-item-avatar>
                <v-img :src="`${$store.state.apiUrl}/${item.companyLogo}`"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ item.companyName }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.categoryName }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon v-if="item.statusId == 2" color="success">mdi-check-circle</v-icon>
                  <v-icon v-if="item.statusId == 1" color="warning">mdi-pause-circle</v-icon>
                  <v-icon v-if="item.statusId == 3" color="error">mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="10">
        <v-sheet rounded="lg" class="glass-container" style="overflow: hidden; margin-right: 10px; overflow-y: scroll" v-if="showCompany">
          <v-tabs v-model="tabs" center-active background-color="transparent" color="primary" grow centered>
            <v-tab>Company Profile</v-tab>
            <v-tab>Company Files</v-tab>
            <v-tab>Company Locations</v-tab>

            <v-tabs-items v-model="tabs" class="pa-5 border-top tab-trans">
              <v-tab-item>
                <v-row>
                  <v-col cols="6">
                    <v-list color="transparent">
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-domain</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Company Name</v-list-item-title>
                          <v-list-item-subtitle>{{ companyDetails.companyName }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-gamepad-circle</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Category</v-list-item-title>
                          <v-list-item-subtitle>{{ companyDetails.categoryName }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-phone</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Phone Number</v-list-item-title>
                          <v-list-item-subtitle>{{ companyDetails.phone }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-file-document-edit-outline</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Registration Number</v-list-item-title>
                          <v-list-item-subtitle>{{ companyDetails.registrationNumber }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-at</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>E-mail</v-list-item-title>
                          <v-list-item-subtitle>{{ companyDetails.mail }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Company Status</v-list-item-title>
                          <v-list-item-subtitle>{{ companyDetails.statusId | status }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action> <ChangeStatus :companyId="companyDetails.idCompany" @reloadTask="updateCompany(companyDetails)" /> </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-col>

                  <v-divider vertical></v-divider>

                  <v-col cols="6">
                    <v-list color="transparent">
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-instagram</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Instagram Account</v-list-item-title>
                          <v-list-item-subtitle>{{ companyDetails.instagramUrl }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-facebook</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Facebook Account</v-list-item-title>
                          <v-list-item-subtitle>{{ companyDetails.facebookUrl }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-twitter</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Twitter Account</v-list-item-title>
                          <v-list-item-subtitle>{{ companyDetails.xUrl }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-web</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Website</v-list-item-title>
                          <v-list-item-subtitle>{{ companyDetails.companyUrl }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-text-long</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Description</v-list-item-title>
                          <v-list-item-subtitle>{{ companyDetails.description }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <div v-if="companyDetails.companyFiles != null">
                  <v-row>
                    <v-col cols="6">
                      <h3>Company Logo</h3>
                      <div style="height: 20px"></div>
                      <v-img :src="`${$store.state.apiUrl}/${companyDetails.companyLogo}`" width="300" height="300" contain></v-img>
                    </v-col>
                    <v-col cols="6">
                      <h3>Company File</h3>
                      <div style="height: 20px"></div>

                      <v-btn color="success" @click="openInNewTab(companyDetails.companyFiles[0].filePath)">
                        <v-icon left> mdi-file-pdf-box </v-icon>
                        Open Pdf
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>No Data Found</div>
              </v-tab-item>

              <v-tab-item>
                <v-list color="transparent">
                  <v-list-item v-for="(loc, index) in companyDetails.companyLocations" :key="index">
                    <v-list-item-avatar>
                      <v-icon>mdi-map-marker-radius</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Location : {{ loc.externalId }}</v-list-item-title>
                      <v-list-item-subtitle>Status : {{ loc.locationStatusId | status }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <ChangeLocationStatus :locationId="loc.idCompanyLocation" @reloadTask="updateCompany(companyDetails)" />
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                </v-list>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-sheet>
      </v-col>
    </v-row>
    <!-- 
    <v-list three-line>
      <v-list-item
        v-for="(item, index) in ItemsFilter"
        :key="index"
        style="margin: 10px; box-shadow: 0px 8px 30px 0px rgba(215, 218, 249, 1); margin: 15px 15px; background: #fff; border-radius: 10px"
      >
        <v-list-item-avatar>
          <v-img :src="`${$store.state.apiUrl}/${item.companyLogo}`"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-row>
            <v-col cols="4">
              <v-list-item-title>{{ item.companyName }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
            </v-col>
            <v-col cols="2">
              {{ item.phone }}
            </v-col>
            <v-col cols="2">
              {{ item.mail }}
            </v-col>
            <v-col cols="2">
              {{ item.registrationNumber }}
            </v-col>
            <v-col cols="2">
              <v-icon v-if="item.statusId == 2" color="success">mdi-check-circle</v-icon>
              <v-icon v-if="item.statusId == 1" color="warning">mdi-clock-time-eleven</v-icon>
              <v-icon v-if="item.statusId == 3" color="error">mdi-close-circle</v-icon>
            </v-col>
          </v-row>
        </v-list-item-content>
        <v-list-item-action-text>
          <ChangeStatus />
        </v-list-item-action-text>
      </v-list-item>
    </v-list>

    <div style="box-shadow: 0px 8px 30px 0px rgba(215, 218, 249, 1); margin: 10px 10px; background: #fff; padding: 10px; border-radius: 10px" v-for="(item, index) in ItemsFilter" :key="index">
      <v-row>
        <v-col cols="1" style="text-align: center">
          <span>{{ index + 1 }}</span>
          <v-avatar size="70" style="box-shadow: 0px 0px 5px 5px #eee; border: 2px solid #bdbdbd">
            <img :src="`${$store.state.apiUrl}/${item.companyLogo}`" alt="John" />
          </v-avatar>
        </v-col>
        <v-col cols="11">
          {{ item.companyName }}
        </v-col>
      </v-row>
    </div>

    <v-container class="container" fluid style="border-radius: 30px">
      <v-card elevation="0" style="border-radius: 30px">
        <v-card-title>
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          <div style="width: 50px"></div>
        </v-card-title>
        <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" item-key="idStudent" class="table-content">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>
          <template v-slot:[`item.companyLogo`]="{ item }">
            <v-avatar size="70">
              <img :src="`${$store.state.apiUrl}/${item.companyLogo}`" alt="John" />
            </v-avatar>
          </template>

          <template v-slot:[`item.statusId`]="{ item }">
            <v-icon v-if="item.statusId == 2" color="success">mdi-check-circle</v-icon>
            <v-icon v-if="item.statusId == 1" color="warning">mdi-clock-time-eleven</v-icon>
            <v-icon v-if="item.statusId == 3" color="error">mdi-close-circle</v-icon>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <ChangeStatus />
          </template>
        </v-data-table>
      </v-card>
    </v-container> -->
  </div>
</template>

<script>
import ChangeStatus from "./../components/ChangeStatus.vue";
import ChangeLocationStatus from "./../components/companyHome/ChangeLocationStatus.vue";

export default {
  components: {
    ChangeStatus,
    ChangeLocationStatus,
  },
  data: () => ({
    overlay: false,
    items: [],
    search: "",
    tabs: null,
    headers: [
      { text: "index", value: "index" },
      { text: "companyLogo", value: "companyLogo" },
      { text: "companyName", value: "companyName" },
      { text: "phone", value: "phone" },
      { text: "mail", value: "mail" },
      { text: "registrationNumber", value: "registrationNumber" },
      { text: "website", value: "companyUrl" },
      { text: "twitter", value: "xUrl" },
      { text: "facebook", value: "facebookUrl" },
      { text: "instagram", value: "instagramUrl" },
      { text: "description", value: "description" },
      { text: "status", value: "statusId" },
      { text: "actions", value: "actions" },
    ],
    companyDetails: "",
    showCompany: false,
  }),
  created() {
    this.initialData();
  },
  methods: {
    openInNewTab(url) {
      window.open(`${this.$store.state.apiUrl}/${url}`, "_blank").focus();
    },
    initialData() {
      let self = this;

      self.$http
        .get(`${self.$store.state.apiUrl}/companies`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.items = res.data;
        });
    },
    selectCompany(company) {
      let self = this;
      self.showCompany = true;
      self.companyDetails = company;
    },

    updateCompany(company) {
      let self = this;
      self.showCompany = true;
      self.$http
        .get(`${self.$store.state.apiUrl}/companies`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.items = res.data;
          self.showCompany = true;
          self.companyDetails = res.data.find((co) => co.idCompany == company.idCompany);
        });
    },
  },
  filters: {
    status(id) {
      if (id == 1) {
        return "Pending";
      } else if (id == 2) {
        return "Accepted";
      } else {
        return "Rejected";
      }
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.items.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.companyName.toLowerCase().includes(v));
        });
      } else {
        return this.items;
      }
    },
  },
};
</script>

<style>
.glass-container {
  background: rgba(255, 255, 255, 0.32) !important;
  border-radius: 16px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter: blur(10px) !important;
  border: 1px solid rgba(255, 255, 255, 1) !important;
  margin-left: 10px;
}

.glass-bar {
  background: rgba(255, 255, 255, 0.62) !important;

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter: blur(10px) !important;
  border: 1px solid rgba(255, 255, 255, 1) !important;
}
.tab-trans {
  background-color: transparent !important;
}
</style>
