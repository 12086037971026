<template>
  <v-app id="inspire">
    <v-app-bar app class="glass-bar" elevation="0">
      <!-- <v-avatar :color="$vuetify.breakpoint.smAndDown ? 'grey darken-1' : 'transparent'" size="32"></v-avatar> -->

      <v-avatar class="hidden-sm-and-down" color="white" size="50">
        <img src="./assets/bif-logo.svg" alt="" />
      </v-avatar>

      <div style="width: 300px; padding-left: 10px">
        <span style="color: #01b9e3">BAGHDAD</span>
        <br />
        <span style="color: #01b9e3">INTERNATIONAL FAIR</span>
      </div>

      <v-tabs centered color="darken-blue darken-1">
        <v-tab v-for="link in links" :key="link.name" @click="pageClicked(link)" style="color: #0070a2">
          {{ link.name }}
        </v-tab>
      </v-tabs>

      <!-- <v-spacer></v-spacer> -->

      <div style="margin: 0 10px; width: 350px" v-if="!$store.state.userData">
        <div v-if="!$store.state.loginUser">
          <v-row>
            <v-col cols="6">
              <v-btn outlined style="color: #0070a2" @click="$router.push('/login').catch((e) => {})">
                <v-icon left> mdi-login-variant </v-icon>
                login
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn color="info" depressed @click="$router.push('/register').catch((e) => {})">
                <v-icon left> mdi-account-plus </v-icon>
                register
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div style="text-align: right" v-else>
          <v-menu bottom min-width="200px" rounded offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon x-large v-on="on">
                <v-avatar color="brown" size="48">
                  <span class="white--text text-h5">{{ $store.state.loginUser.userName.slice(0, 2) }}</span>
                </v-avatar>
              </v-btn>
            </template>
            <v-card>
              <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                  <v-avatar color="brown">
                    <span class="white--text text-h5">{{ $store.state.loginUser.userName.slice(0, 2) }}</span>
                  </v-avatar>
                  <h3>{{ $store.state.loginUser.userName }}</h3>
                  <p class="text-caption mt-1">
                    {{ $store.state.loginUser.phone }}
                  </p>
                  <v-divider class="my-3"></v-divider>
                  <v-btn depressed rounded text @click="logout()"> Logout </v-btn>
                </div>
              </v-list-item-content>
            </v-card>
          </v-menu>
        </div>
      </div>
      <v-avatar style="text-align: right" @click="$router.push('/home').catch((e) => {})" v-else>
        <img :src="`${$store.state.apiUrl}/${$store.state.userData.companyLogo}`" alt="John" />
      </v-avatar>
    </v-app-bar>

    <v-main style="margin: 0px; background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    links: [
      { name: "Home", link: "/" },
      { name: "Map", link: "/map" },
      // { name: "About", link: "/about" },
      { name: "Contact", link: "/contact" },
      // { name: "Companies", link: "/companies" },
    ],
  }),
  created() {
    let self = this;
    this.$store.dispatch("getUserDate").then(() => {
      if (this.$store.state.userData != null) {
        let credentials = {
          password: this.$store.state.userData.password,
          mail: this.$store.state.userData.mail,
        };
        self.$http
          .post(`${self.$store.state.apiUrl}/login`, credentials)
          .then((res) => {
            localStorage.setItem("company", JSON.stringify(res.data.token));
          })
          .catch((e) => {
            console.log(e);
            // this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
          });
      } else {
        this.$store.dispatch("getAdminDate").then(() => {
          console.log(this.$store.state.loginUser);
          if (this.$store.state.loginUser != null) {
            this.links.push({ name: "Companies", link: "/companies" });
          } else {
          }
        });
      }
      console.log(this.$store.state.userData);
    });
  },
  methods: {
    pageClicked(page) {
      this.$router.push(page.link).catch(() => {});
    },
    logout() {
      localStorage.removeItem("userLogin");
      this.$router.push("/").catch((e) => {});
      location.reload();
    },
  },
};
</script>
<style>
.theme--light.v-btn.v-btn--has-bg {
  /* background-image: radial-gradient(circle 382px at 50% 50.2%, #0093e9 0.1%, rgba(3, 1, 50, 1) 100.2%) !important; */
  background: linear-gradient(180deg, #50bfff, #0093e9);
  border: 1px solid #50bfff;
  /* box-shadow: 0 0 8px #50bfff, inset 0 0 0 2px #fff3, inset 0 -2px 2px #50bfff; */
}
.v-tabs-slider-wrapper {
  color: #0070a2;
}
.error-button {
  background: linear-gradient(180deg, #ff506d, #e90000) !important;
  border: 1px solid #e90000 !important;
  box-shadow: none !important;
}
</style>
