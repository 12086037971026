<template>
  <div style="background-color: #ffffff">
    <v-container class="container" fluid>
      <v-row>
        <v-col cols="8" class="login-image"> </v-col>
        <v-col cols="4" align="center" align-self="center" style="height: 95vh; padding-top: 10%">
          <v-container class="container" fluid>
            <div style="margin-bottom: 20px">
              <v-img :src="require('../assets/bif-logo.svg')" class="my-3" contain height="200" />
            </div>
            <div style="margin: auto; width: 500px">
              <v-text-field outlined v-model="mail" label="Company Email"></v-text-field>
              <v-text-field outlined v-model="password" type="password" label="Password" @keyup.enter="login()"></v-text-field>
              <v-row>
                <v-col cols="6">
                  <v-btn block large @click="login" color="info">
                    <v-icon left> mdi-login-variant </v-icon>
                    Login As Company
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn block large @click="loginAdmin" style="background: #d6e0f0">
                    <v-icon left> mdi-login-variant </v-icon>
                    Login As Admin
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    password: "",
    mail: "",
  }),
  methods: {
    login() {
      let self = this;
      // this.$router.push("/");
      if (self.mail && self.password) {
        let credentials = {
          password: self.password,
          mail: self.mail,
        };
        self.$http
          .post(`${self.$store.state.apiUrl}/login`, credentials)
          .then((res) => {
            localStorage.setItem("company", JSON.stringify(res.data.token));
            this.$router.push("/").catch((e) => {});
            location.reload();
          })
          .catch((e) => {
            console.log(e);
            this.$toasted.error("Error with Company credentials");
          });
      } else {
        this.$toasted.error("Please fill All Fields");
      }
    },
    loginAdmin() {
      let self = this;
      // this.$router.push("/");
      if (self.mail && self.password) {
        let credentials = {
          password: self.password,
          userName: self.mail,
        };
        self.$http
          .post(`${self.$store.state.apiUrl}/userLogin`, credentials)
          .then((res) => {
            localStorage.setItem("userLogin", JSON.stringify(res.data.token));
            this.$router.push("/").catch((e) => {});
            location.reload();
          })
          .catch((e) => {
            console.log(e);
            this.$toasted.error("Error with User credentials");
          });
      } else {
        this.$toasted.error("Please fill All Fields");
      }
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
  },
};
</script>

<style>
.register:hover {
  cursor: pointer;
}
.login-image {
  margin: 0px !important;
  padding: 0px !important;
  background: url("./../assets/bac.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
</style>
