import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Toasted from "vue-toasted";
import axios from "axios";
import VueFileAgent from "vue-file-agent";
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
import Flicking from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
import "@egjs/vue-flicking/dist/flicking-inline.css";
import VuePdfApp from "vue-pdf-app";

// import "@mappedin/mappedin-js/lib/index.css";

Vue.use(Flicking);
Vue.config.productionTip = false;
Vue.http = Vue.prototype.$http = axios;
Vue.use(VueFileAgent);
Vue.component("vue-pdf-app", VuePdfApp);

Vue.use(Toasted, {
  iconPack: "material",
  duration: 4000,
  position: "top-center",
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
