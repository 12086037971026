<template>
  <div>
    <div :style="{ background: `url(${require('../assets/dbg.jpg')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover !important' }" style="width: 100vw; height: 95vh">
      <h1 style="text-align: center; color: #ffffff; padding-top: 500px; font-size: 60px">BAGHDAD INTERNATIONAL FAIR</h1>
      <h3 style="text-align: center; color: #ffffff; padding-top: 30px; font-size: 40px">SESSION 47 10-19 Jan. 2024</h3>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
</script>
